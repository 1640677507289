import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  Col,
  Form,
  Input,
  List,
  message,
  Popconfirm,
  Row,
  Spin,
  Switch,
} from "antd";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import Modal from "antd/lib/modal/Modal";
import Cookies from "js-cookie";

import "../css/site-users.css";
import PhoneValidator from "../functions/PhoneValidator";
import { prefixSelector } from "./PhoneNumberInput";

const UserDetail = ({ user }) => {
  if (user.name !== undefined) {
    return (
      <>
        <p>
          <b>Name:</b>&nbsp;{user.name.first} {user.name.middle ?? ""}{" "}
          {user.name.last}
        </p>
        <p>
          <b>Phone:</b>&nbsp;{user.phone_number}
        </p>
        <p>
          <b>Email:</b>&nbsp;
          {user.email ?? "-"}
        </p>
        <p>
          <b>Company:</b>&nbsp;{user.company ?? "-"}
        </p>
      </>
    );
  }
  return <div />;
};

const SiteUsersPage = () => {
  const [users, setUsers] = useState([]);
  const [addLoading, setAddLoading] = useState(false);
  const [initLoading, setInitLoading] = useState(false);
  const [updateLoading, setUpdateLoading] = useState(false);
  const [phoneLen, setPhoneLen] = useState(0);
  const [siteUsers, setSiteUsers] = useState([]);
  const [user, setItem] = useState({});
  const [foundUser, setFoundUser] = useState({});
  const [modalVisibility, setModalVisibility] = useState(false);
  const [formKey, setFormKey] = useState(0);

  const formRef = React.createRef();
  const updateFormRef = React.createRef();

  const site_id = Cookies.get("site_id");

  const getUserPermissions = () => {
    let url = `  ${process.env.REACT_APP_API_URL}/sites/${site_id}/users`;
    setUsers([]);
    fetch(url, {
      headers: {
        Authorization: `Bearer ${Cookies.get("token")}`,
      },
    })
      .then((res) => {
        if (res.status !== 200) {
          throw new Error(res.statusText);
        } else {
          return res.json();
        }
      })
      .then((res) => {
        console.log(res);
        setInitLoading(false);
        setSiteUsers(res != null ? res : []);
      })
      .catch((e) => {
        message.error(e.message);
      });
  };

  const getUser = (by, value, setLoading, onError) => {
    let url = `${process.env.REACT_APP_API_URL}/users`;
    if (by === "id") {
      url = `${process.env.REACT_APP_API_URL}/users/${value}`;
    } else if (by === "phone") {
      let phone = encodeURIComponent(value);
      url = `${process.env.REACT_APP_API_URL}/users?phone=${phone}`;
    }

    return fetch(url, {
      headers: {
        Authorization: `Bearer ${Cookies.get("token")}`,
      },
    })
      .then((res) => {
        if (res.status !== 200) {
          throw new Error(res.statusText);
        } else {
          return res.json();
        }
      })
      .then((res) => {
        setLoading(false);
        return Promise.resolve(res);
      })
      .catch((e) => {
        onError(e.message);
        return Promise.resolve([]);
      });
  };

  const getUsers = () => {
    if (siteUsers.length > 0) {
      siteUsers.forEach((userPermissions) => {
        getUser(
          "id",
          userPermissions.user_id,
          setInitLoading,
          message.error
        ).then((res) =>
          setUsers((prevState) => {
            if (res != null) {
              prevState = prevState.concat(res);
            }
            return prevState;
          })
        );
      });
    }
  };

  const submit = () => {
    setAddLoading(true);
    let data = {
      user_id: foundUser.id,
      permitted: true,
    };
    let url = `  ${process.env.REACT_APP_API_URL}/sites/${site_id}/users`;
    fetch(url, {
      body: JSON.stringify(data),
      method: "POST",
      headers: {
        Authorization: `Bearer ${Cookies.get("token")}`,
      },
    })
      .then((res) => {
        setAddLoading(false);
        if (res.status !== 200) {
          message.error("User not added.");
          throw new Error(res.statusText);
        } else {
          getUserPermissions();
          return res.json();
        }
      })
      .then((res) => {
        console.log(res);
        message.success("User successfully added.");
        if (formRef.current != null) {
          formRef.current.resetFields();
        }
      })
      .catch((e) => {
        message.error(e.message);
      });
  };

  const deleteExtra = () => {};

  const onClose = () => {
    if (!updateLoading) {
      setModalVisibility(false);
      setItem({});
    }
  };

  const update = (permitted, userId) => {
    setUpdateLoading(true);
    let data = {
      user_id: userId,
      permitted: permitted,
    };
    let url = `  ${process.env.REACT_APP_API_URL}/sites/${site_id}/users`;
    fetch(url, {
      body: JSON.stringify(data),
      method: "PUT",
      headers: {
        Authorization: `Bearer ${Cookies.get("token")}`,
      },
    })
      .then((res) => {
        setUpdateLoading(false);
        if (res.status !== 200) {
          message.error("User permission not updated.");
          throw new Error(res.statusText);
        } else {
          getUserPermissions();
          return res.json();
        }
      })
      .then(() => {
        message.success("User permission updated");
      })
      .catch((e) => {
        message.error(e.message);
      });
  };

  useEffect(getUserPermissions, [site_id]);
  useEffect(getUsers, [siteUsers]);

  return (
    <>
      <div className="container-div">
        <div className="right-div">
          <Card title="Add User">
            <Form
              name="register"
              onFinish={submit}
              scrollToFirstError
              style={{ textAlign: "center" }}
              ref={formRef}
            >
              <Form.Item
                name="phone"
                rules={[
                  {
                    required: true,
                    message: "Please input a phone number.",
                  },
                  PhoneValidator(),
                ]}
              >
                <Input
                  addonBefore={prefixSelector}
                  style={{ width: "100%" }}
                  placeholder="Phone number"
                  onChange={(event) => {
                    if (event.target.value.length > 8) {
                        console.log(formRef.current);
                      getUser(
                        "phone",
                        `${formRef.current.getFieldValue("prefix")}${event.target.value}`,
                        setAddLoading,
                        () => {
                          setFoundUser({});
                        }
                      ).then((user) => {
                        setFoundUser(user);
                        console.log(user);
                      });
                    }
                    setPhoneLen(event.target.value.length + 4);
                  }}
                />
              </Form.Item>
              <Spin spinning={addLoading}>
                <div hidden={phoneLen === 0} style={{ textAlign: "left" }}>
                  {foundUser.name ? (
                    <UserDetail user={foundUser} />
                  ) : (
                    <p style={{ margin: 30 }}>
                      No user found with that phone number
                    </p>
                  )}
                </div>
              </Spin>
              <Row gutter={24} style={{ textAlign: "center" }}>
                <Col span={24} style={{ textAlign: "center" }}>
                  <Form.Item>
                    <Button
                      type="primary"
                      htmlType="submit"
                      style={{ width: "100%" }}
                      loading={addLoading}
                      disabled={
                        foundUser.name === undefined ||
                        siteUsers.some(
                          (value) => value.user_id === foundUser.id
                        )
                      }
                    >
                      Add
                    </Button>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Card>
        </div>
        <div className="left-div">
          <Card bordered={false} className="left-card">
            <div className="scrollable-div shorter-div">
              <List
                className="demo-loadmore-list"
                loading={initLoading}
                itemLayout="horizontal"
                dataSource={users}
                renderItem={(user) => (
                  <List.Item actions={[]}>
                    <Card
                      style={{ marginBottom: "20px", width: "100%" }}
                      bordered={false}
                    >
                      <UserDetail user={user} />
                      <div style={{ display: "flex" }}>
                        <div style={{ flexGrow: 2 }} />
                        <Button
                          icon={<EditOutlined />}
                          type="link"
                          onClick={() => {
                            setItem(user);
                            setModalVisibility(true);
                            setFormKey((prevState) => prevState + 1);
                          }}
                        />
                        <div style={{ flexGrow: 1 }} />
                        <Popconfirm
                          title="Are you sure you want to delete this extra?"
                          onConfirm={() => {
                            deleteExtra(user.id);
                          }}
                          disabled={true}
                        >
                          <Button
                            disabled={true}
                            icon={<DeleteOutlined />}
                            type="link"
                            style={{ color: "red" }}
                          />
                        </Popconfirm>
                        <div style={{ flexGrow: 2 }} />
                      </div>
                    </Card>
                  </List.Item>
                )}
              />
            </div>
          </Card>
        </div>
      </div>
      <Modal
        visible={modalVisibility}
        onCancel={onClose}
        closable={true}
        footer={null}
      >
        <Card bordered={false}>
          <Form
            key={formKey.toString()}
            name="update"
            onFinish={update}
            scrollToFirstError
            style={{ textAlign: "left" }}
            ref={updateFormRef}
            initialValues={user}
          >
            <UserDetail user={user} />
            <Switch
              loading={updateLoading}
              defaultChecked={siteUsers.some(
                (value) => value.user_id === user.id && value.permitted
              )}
              onChange={(checked) => update(checked, user.id)}
            />
          </Form>
        </Card>
      </Modal>
    </>
  );
};

export default SiteUsersPage;
