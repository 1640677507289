import React, { Component } from "react";
import { Button, Card, Form, Input, message, Spin } from "antd";
import Title from "antd/lib/typography/Title";
import {
  CloseOutlined,
  LoadingOutlined,
  QrcodeOutlined,
} from "@ant-design/icons";
import Cookies from "js-cookie";
import { Link, Route } from "react-router-dom";
import ScannerScanModal from "./ScannerScanModal";
import "../css/scanner.css";
import Modal from "antd/lib/modal";
import { prefixSelector } from "./PhoneNumberInput";

export default class ScannerExitScanner extends Component {
  formRef = React.createRef();

  constructor() {
    super();
    this.state = {
      codeLoading: false,
      drawerVisible: false,
      currentUserName: "",
      email: "",
      company: "",
      clp: "",
      dob: Date,
      session: null,
      scanning: false,
      accessKey: "",
    };
  }

  getUserInfo(phone, code) {
    phone = encodeURIComponent(phone);
    this.setState({ codeLoading: true, accessKey: code });
    fetch(`  ${process.env.REACT_APP_API_URL}/users?phone=${phone}`, {
      headers: {
        Authorization: `Bearer ${Cookies.get("token")}`,
      },
    })
      .then((res) => {
        if (res.status !== 200) {
          this.setState({
            codeLoading: false,
          });
          throw new Error(res.statusText);
        } else {
          return res.json();
        }
      })
      .then((res) => {
        if (res.access_key === code) {
          this.setState({
            drawerVisible: true,
            currentUserName: `${res.name.first} ${res.user.name.middle!=null? `${res.user.name.middle} `:""}${res.name.last}`,
            email: res.email,
            phone: res.phone_number,
            company: res.company,
            clp: res.car_license_plate,
            dob: new Date(res.dob),
            codeLoading: false,
            accessKey: code,
          });
        } else {
          this.setState({
            codeLoading: false,
          });
        }
      })
      .catch((e) => {
        message.error(e.message);
        this.setState({
          codeLoading: false,
        });
      });
  }

  handleScan = (data) => {
    if (data != null && !this.state.drawerVisible) {
      data = JSON.parse(data);
      this.getUserInfo(data.phone, data.access_key);
    }
  };

  handleError = (e) => {
    message.error(e.message);
  };

  handleFormSubmit = (values) => {
    this.getUserInfo(`${values.prefix}${values.phone}`, values["access_key"]);
  };

  onClose = () => {
    this.setState({
      codeLoading: false,
      drawerVisible: false,
      phone: "",
      currentUserName: "",
      email: "",
      company: "",
      clp: "",
      dob: null,
    });
  };

  endSession = () => {
    let data = {
      scanner_id: Cookies.get("scanner_id"),
      phone: this.state.phone,
      access_key: this.state.accessKey,
    };
    this.setState({
      scanning: true,
    });
    fetch(
      `  ${process.env.REACT_APP_API_URL}/sites/${Cookies.get(
        "site_id"
      )}/visits`,
      {
        method: "PUT",
        body: JSON.stringify(data),
        headers: {
          Authorization: `Bearer ${Cookies.get("token")}`,
        },
      }
    )
      .then((res) => {
        if (res.status !== 200) {
          throw new Error(res.statusText);
        } else {
          return res.json();
        }
      })
      .then(() => {
        this.formRef.current.resetFields();
        this.onClose();
        message.success("Session ended");
      })
      .catch((e) => {
        message.error(e.message);
      })
      .finally(
        this.setState({
          scanning: false,
        })
      );
  };

  render() {
    return (
      <div className="scanner-container-exit">
        <Card
          className="scanner-card"
          title={
            <Title level={4} style={{ textAlign: "center" }}>
              Exit Form
            </Title>
          }
        >
          <Spin
            spinning={this.state.codeLoading}
            indicator={<LoadingOutlined />}
          >
            <Form onFinish={this.handleFormSubmit} ref={this.formRef}>
              <Form.Item
                name="phone"
                rules={[
                  { required: true, message: "Phone number is required" },
                  () => ({
                    validator(rule, value) {
                      if (!isNaN(value)) {
                        return Promise.resolve();
                      }
                      return Promise.reject(
                        "Please enter a valid phone number."
                      );
                    },
                  }),
                ]}
              >
                <Input
                  addonBefore={prefixSelector}
                  style={{ width: "100%" }}
                  placeholder="Phone number"
                />
              </Form.Item>
              <Form.Item
                name="access_key"
                rules={[
                  { required: true, message: "Access key is required" },
                  { len: 4, message: "Access key is only 4 digits" },
                  () => ({
                    validator(rule, value) {
                      if (!isNaN(value)) {
                        return Promise.resolve();
                      }
                      return Promise.reject(
                        "Access key can only contain numbers (0-9)."
                      );
                    },
                  }),
                ]}
              >
                <Input placeholder="Access Key" />
              </Form.Item>
              <Form.Item name="note" help="Scanners note regarding the exit">
                <Input.TextArea placeholder="Scanner's Note" />
              </Form.Item>

              <Form.Item>
                <Button htmlType="submit">Get Info</Button>
              </Form.Item>
              <p>or</p>
              <Link to="/scanner/exit/qr">
                <Button icon={<QrcodeOutlined />}>Scan QR</Button>
              </Link>
            </Form>
          </Spin>
        </Card>

        <Route
          path="/scanner/exit/qr"
          render={() => (
            <ScannerScanModal
              loading={this.state.codeLoading}
              onScan={this.handleScan}
              onError={this.handleError}
              source="/scanner/exit"
            />
          )}
        />
        <Modal
          title={this.state.currentUserName}
          height="50vh"
          closable={true}
          onCancel={this.onClose}
          footer={null}
          visible={this.state.drawerVisible}
        >
          <p>{`Phone Number: ${
            this.state.phone !== undefined ? this.state.phone : "-"
          }`}</p>
          <p>{`Email: ${
            this.state.email !== undefined ? this.state.email : "-"
          }`}</p>
          <p>{`Company: ${
            this.state.company !== undefined ? this.state.company : "-"
          }`}</p>
          <p>{`Car License Plate: ${
            this.state.clp !== undefined ? this.state.clp : "-"
          }`}</p>
          <p>{`Date of Birth: ${
            this.state.dob !== undefined ? this.state.dob : "-"
          }`}</p>
          <p style={{ textAlign: "center" }}>
            <Button
              icon={<CloseOutlined />}
              type="primary"
              shape="circle"
              size="large"
              danger
              loading={this.state.scanning}
              onClick={this.endSession}
            />
          </p>
        </Modal>
      </div>
    );
  }
}
