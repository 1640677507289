import React, {Component} from "react";
import {Button, Card, Checkbox, Col, Form, Input, List, message, Popconfirm, Row, Select, Tooltip,} from "antd";
import {DeleteOutlined, EditOutlined, SaveOutlined} from "@ant-design/icons";

import "../css/site-extras.css";
import Cookies from "js-cookie";
import PhoneFormatter from "../functions/PhoneFormatter";
import Modal from "antd/lib/modal/Modal";
import FormItem from "antd/lib/form/FormItem";

export default class SiteExtrasPage extends Component {
  state = {
    item: {},
    initLoading: true,
    loading: false,
    list: [],
    modalVisibility: false,
    updateLoading: false,
    submitLoading: false,
    formKey: 0,
  };

  formRef = React.createRef();
  updateFormRef = React.createRef();

  getData = async () => {
    const site_id = Cookies.get("site_id");

    let url = `  ${process.env.REACT_APP_API_URL}/sites/${site_id}/extras`;
    fetch(url, {
      headers: {
        Authorization: `Bearer ${Cookies.get("token")}`,
      },
    })
        .then((res) => {
          if (res.status !== 200) {
            throw new Error(res.statusText);
          } else {
            return res.json();
          }
        })
        .then((res) => {
          this.setState({
            initLoading: false,
            list: res != null ? res : [],
          });
        })
        .catch((e) => {
          message.error(e.message)
        });
  };

  onClose = () => {
    this.setState({
      modalVisibility: false,
      item: {},
    });
  };

  update = (values) => {
    const site_id = Cookies.get("site_id");
    this.setState({
      updateLoading: true,
    });
    let data = {
      name: values["name"],
      description: values["description"],
      type: values["type"],
      constant: values["constant"],
      phone_number: PhoneFormatter(values["phone"]),
    };
    let url = `  ${process.env.REACT_APP_API_URL}/sites/${site_id}/extras/${values["id"]}`;
    fetch(url, {
      body: JSON.stringify(data),
      method: "PUT",
      headers: {
        Authorization: `Bearer ${Cookies.get("token")}`,
      },
    })
        .then((res) => {
          this.setState({updateLoading: false});
          if (res.status !== 200) {
            message.error("Update failed. You must have missed a field.");
            throw new Error(res.statusText);
          } else {
            this.getData();
            return res.json();
          }
        })
        .then(() => {
          message.success("Successfully updated.");
          // this.updateFormRef.current.resetFields();
          this.onClose();
        })
        .catch((e) => {
          message.error(e.message);
        });
  };

  submit = (values) => {
    const site_id = Cookies.get("site_id");
    this.setState({
      submitLoading: true,
    });
    let data = {
      name: values["name"],
      description: values["description"],
      type: values["type"],
      constant: values["constant"],
      phone_number: PhoneFormatter(values["phone"]),
    };
    let url = `  ${process.env.REACT_APP_API_URL}/sites/${site_id}/extras`;
    fetch(url, {
      body: JSON.stringify(data),
      method: "POST",
      headers: {
        Authorization: `Bearer ${Cookies.get("token")}`,
      },
    })
        .then((res) => {
          this.setState({submitLoading: false});
          if (res.status !== 200) {
            message.error("Extra not added.");
            throw new Error(res.statusText);
          } else {
            this.getData();
            return res.json();
          }
        })
        .then(() => {
          message.success("Extra successfuly added.");

          this.formRef.current.resetFields();
        })
        .catch((e) => {
          message.error(e.message);
        });
  };

  deleteExtra = (id) => {
    const site_id = Cookies.get("site_id");
    this.setState({
      initLoading: true,
    });
    let url = `  ${process.env.REACT_APP_API_URL}/sites/${site_id}/extras/${id}`;
    fetch(url, {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${Cookies.get("token")}`,
      },
    })
        .then((res) => {
          this.setState({initLoading: false});
          if (res.status !== 200) {
            message.error("Extra not deleted.");
            throw new Error(res.statusText);
          } else {
            this.getData();
            return res.json();
          }
        })
        .then(() => {
          message.success("Extra successfuly deleted.");
        })
        .catch((e) => {
          message.error(e.message);

        });
  };

  componentDidMount() {
    this.getData();
  }

  render() {
    return (
        <>
          <div className="container-div">
            <div className="right-div">
              <Card title="Add Extra">
                <Form
                    name="register"
                    onFinish={this.submit}
                    scrollToFirstError
                    style={{textAlign: "center"}}
                    ref={this.formRef}
                >
                  <Tooltip title="Enter the name of the required extra information.">
                    <Form.Item
                        name="name"
                        rules={[
                          {
                            required: true,
                            message: "Please input a name.",
                          },
                        ]}
                    >
                      <Input placeholder="Name"/>
                    </Form.Item>
                  </Tooltip>
                  <Tooltip title="Enter enter a description about the extra information required. (100 characters max)">
                    <Form.Item
                        name="description"
                        rules={[
                          {
                            required: true,
                            message: "Please enter a description.",
                          },
                          {
                            max: 100,
                            message:
                                "Description should not be more than 100 characters.",
                          },
                        ]}
                    >
                      <Input placeholder="Description"/>
                    </Form.Item>
                  </Tooltip>
                  <Tooltip title="Chose the type of the extra information required.">
                    <Form.Item name="type" initialValue="text">
                      <Select>
                        <Select.Option value="text">Text</Select.Option>
                        <Select.Option value="number">Number</Select.Option>
                      </Select>
                    </Form.Item>
                  </Tooltip>
                  <Tooltip
                      title="Check this box if the value doesn't change very often and you want it to be remembred upon entry.">
                    <Form.Item
                        name="constant"
                        valuePropName="checked"
                        initialValue={false}
                    >
                      <Checkbox>Constant value</Checkbox>
                    </Form.Item>
                  </Tooltip>
                  <Row gutter={24} style={{textAlign: "center"}}>
                    <Col span={24} style={{textAlign: "center"}}>
                      <Form.Item>
                        <Button
                            type="primary"
                            htmlType="submit"
                            style={{width: "100%"}}
                            loading={this.state.submitLoading}
                        >
                          Add
                        </Button>
                      </Form.Item>
                    </Col>
                  </Row>
                </Form>
              </Card>
            </div>
            <div className="left-div">
              <Card bordered={false} className="left-card">
                <div className="scrollable-div shorter-div">
                  <List
                      className="demo-loadmore-list"
                      loading={this.state.initLoading}
                      itemLayout="horizontal"
                      dataSource={this.state.list}
                      renderItem={(item) => (
                          <List.Item actions={[]}>
                            <Card
                                style={{marginBottom: "20px", width: "100%"}}
                                bordered={false}
                            >
                              <p>
                                <b>Name:</b>&nbsp;{item.name}
                              </p>
                              <p>
                                <b>Description:</b>&nbsp;{item.description}
                              </p>
                              <p>
                                <b>Type:</b>&nbsp;
                                {item.type === "number" ? "Number" : "Text"}
                              </p>
                              <p>
                                <b>Constant:</b>&nbsp;{item.constant ? "Yes" : "No"}
                              </p>
                              <div style={{display: "flex"}}>
                                <div style={{flexGrow: 2}}/>
                                <Button
                                    icon={<EditOutlined/>}
                                    type="link"
                                    onClick={() => {
                                      this.setState({
                                        item: item,
                                        modalVisibility: true,
                                        formKey: this.state.formKey + 1,
                                      });
                                    }}
                                />
                                <div style={{flexGrow: 1}}/>
                                <Popconfirm
                                    title="Are you sure you want to delete this extra?"
                                    onConfirm={() => {
                                      this.deleteExtra(item.id);
                                    }}
                                >
                                  <Button
                                      icon={<DeleteOutlined/>}
                                      type="link"
                                      style={{color: "red"}}
                                  />
                                </Popconfirm>
                                <div style={{flexGrow: 2}}/>
                              </div>
                            </Card>
                          </List.Item>
                      )}
                  />
                </div>
              </Card>
            </div>
          </div>
          <Modal
              visible={this.state.modalVisibility}
              onCancel={this.onClose}
              closable={true}
              footer={null}
          >
            <Card bordered={false}>
              <Form
                  key={this.state.formKey.toString()}
                  name="update"
                  onFinish={this.update}
                  scrollToFirstError
                  style={{textAlign: "center"}}
                  ref={this.updateFormRef}
                  initialValues={this.item}
              >
                <FormItem name="id" initialValue={this.state.item.id}>
                  <Input hidden={true} readOnly={true}/>
                </FormItem>
                <Form.Item
                    shouldUpdate={true}
                    initialValue={this.state.item.name}
                    name="name"
                    rules={[
                      {
                        required: true,
                        message: "Please input a name.",
                      },
                    ]}
                >
                  <Input placeholder="Name"/>
                </Form.Item>
                <Form.Item
                    name="description"
                    initialValue={this.state.item.description}
                    rules={[
                      {
                        required: true,
                        message: "Please enter a description.",
                      },
                      {
                        max: 100,
                        message:
                            "Description should not be more than 100 characters.",
                      },
                    ]}
                >
                  <Input placeholder="Description"/>
                </Form.Item>

                <Form.Item name="type" initialValue={this.state.item.type}>
                  <Select>
                    <Select.Option value="text">Text</Select.Option>
                    <Select.Option value="number">Number</Select.Option>
                  </Select>
                </Form.Item>
                <Form.Item
                    name="constant"
                    valuePropName="checked"
                    initialValue={this.state.item.constant}
                >
                  <Checkbox>Constant value</Checkbox>
                </Form.Item>
                <Form.Item>
                  <Button
                      icon={<SaveOutlined/>}
                      type="primary"
                      htmlType="submit"
                      loading={this.state.updateLoading}
                  >
                    Save
                  </Button>
                </Form.Item>
              </Form>
            </Card>
          </Modal>
        </>
    );
  }
}
