import React from "react";
import { Form, Select } from "antd";

const { Option } = Select;
export const prefixSelector = (
  <Form.Item name="prefix" noStyle initialValue="+254">
    <Select style={{ width: 90 }}>
      <Option value="+254">+254</Option>
      <Option value="+251">+251</Option>
    </Select>
  </Form.Item>
);
