import React, {useState} from "react";
import {Button, Form, Input, message} from "antd";
import {LockOutlined, MailOutlined} from "@ant-design/icons";
import {Link, useHistory, useLocation} from "react-router-dom";
import Cookies from "js-cookie";

export default function SiteLoginForm() {
    const [isLoggedIn] = useState(false);
    const [isError, setIsError] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    let history = useHistory();
    let location = useLocation();

    let {from} = location.state || {from: {pathname: "/visits"}};

    const submit = (values) => {
        setIsLoading(true);
        let url = `  ${process.env.REACT_APP_API_URL}/sites/login`;
        let data = {
            email: values["email"],
            password: values["password"],
        };
        fetch(url, {
            method: "POST",
            body: JSON.stringify(data),
        })
            .then((res) => {
                setIsLoading(false);
                if (res.status !== 200) {
                    setIsError(true);
                    throw new Error(res.statusText);
                } else {
                    return res.json();
                }
            })
            .then(async (res) => {
                let site = await fetch(`${process.env.REACT_APP_API_URL}/sites?id=${res.id}`, {
                    headers: {Authorization: `Bearer ${res.token}`}
                }).then(res => res.json());
                Cookies.set("site_code", site.code)
                Cookies.set("site_id", res.id, {expires: 365});
                Cookies.set("token", res.token, {expires: 365});
                Cookies.set("as", "site", {expires: 365});
            })
            .catch((e) => {
                message.error(e.message);
                setIsLoading(false);
                if (!isError) {
                    message.error("Authentication error!");
                }
            }).finally(() => history.push(from));
    };
    const regStyle = {
        marginTop: "15px",
    };

    if (isLoggedIn) {
        history.push(from);
    }
    return (
        <Form name="normal_login" className="login-form" onFinish={submit}>
            <Form.Item
                name="email"
                rules={[
                    {required: true, message: "Please input your email!"},
                    {
                        type: "email",
                        message: "Please enter a valid email",
                    },
                ]}
            >
                <Input prefix={<MailOutlined/>} placeholder="Email"/>
            </Form.Item>
            <Form.Item
                name="password"
                rules={[{required: true, message: "Please input your Password!"}]}
            >
                <Input.Password prefix={<LockOutlined/>} placeholder="Password"/>
            </Form.Item>
            {isError && (
                <p style={{color: "red"}}>
                    The email or password provided were incorrect!
                </p>
            )}
            <Form.Item>
                <Button
                    type="primary"
                    // className="accent2-bg accent2-outline"
                    htmlType="submit"
                    size="middle"
                    shape="round"
                    loading={isLoading}
                >
                    Log in
                </Button>
                <p style={regStyle}>Don't have an account?</p>
                <Link to="/register">
                    <Button type="link" size="small" style={{padding: 0, margin: 0}}>
                        Register now!
                    </Button>
                </Link>
            </Form.Item>
        </Form>
    );
}
