import React, { Component } from "react";
import {
  Button,
  Card,
  Form,
  Input,
  List,
  message,
  Select,
  Spin,
  Switch,
} from "antd";

import Title from "antd/lib/typography/Title";
import {
  CheckOutlined,
  LoadingOutlined,
  QrcodeOutlined,
} from "@ant-design/icons";
import Cookies from "js-cookie";
import { Link, Route } from "react-router-dom";

import ScannerScanModal from "./ScannerScanModal";

import "../css/scanner.css";
import Modal from "antd/lib/modal/Modal";
import PhoneValidator from "../functions/PhoneValidator";
import { prefixSelector } from "./PhoneNumberInput";

const { Option } = Select;
export default class ScannerEntryScanner extends Component {
  formRef = React.createRef();
  extFormRef = React.createRef();
  noteFormRef = React.createRef();

  constructor() {
    super();
    this.state = {
      codeLoading: false,
      modalVisibility: false,
      phone: "",
      currentUserName: "",
      email: "",
      company: "",
      companyId: "",
      clp: "",
      dob: Date,
      pass: null,
      scanning: false,
      accessKey: "",
      extras: [],
      companies: [],
      companyOptions: [],
      id_number: null,
      note: "",
      flagged: false,
    };
  }

  onChange = (value) => {
    this.setState({
      companyId: value,
    });
  };

  getUserInfo(phone, code, note) {
    this.setState({ codeLoading: true, accessKey: code, note: note });
    let data = {
      phone: phone,
      access_key: this.state.accessKey,
      note: this.state.note,
    };
    fetch(
      `  ${process.env.REACT_APP_API_URL}/sites/${Cookies.get(
        "site_id"
      )}/request-visit`,
      {
        method: "POST",
        body: JSON.stringify(data),
        headers: {
          Authorization: `Bearer ${Cookies.get("token")}`,
        },
      }
    )
      .then((res) => {
        if (res.status !== 200) {
          this.setState({
            codeLoading: false,
          });
          throw new Error(res.statusText);
        } else {
          return res.json();
        }
      })
      .then((res) => {
        this.setState(
          {
            modalVisibility: true,
            currentUserName: `${res.user.name.first} ${res.user.name.middle!=null? `${res.user.name.middle} `:""}${res.user.name.last}`,
            email: res.user.email,
            id_number: res.user.id_number,
            phone: res.user.phone_number,
            company: res.user.company,
            clp: res.user.car_license_plate,
            dob: new Date(res.user.dob),
            codeLoading: false,
            accessKey: res.user.access_key,
            extras: res.extras != null ? res.extras : [],
            companies: res.companies,
            flagged: Boolean(res.flagged),
          },
          () => {
            let options = [];
            //TODO: add time comparison
            if (
              this.state.companies !== undefined &&
              this.state.companies !== null
            ) {
              for (let i = 0; i < this.state.companies.length; i++) {
                options.push(
                  <Option key={i} value={this.state.companies[i].id}>
                    {this.state.companies[i].name}
                  </Option>
                );
              }
              this.setState({
                companyOptions: options,
                companyId: this.state.companies[0].id,
              });
            }
          }
        );
      })
      .catch((e) => {
        message.error(e.message);
        this.setState({
          codeLoading: false,
        });
      });
  }

  handleScan = (data) => {
    if (data != null && !this.state.modalVisibility) {
      data = JSON.parse(data);
      this.getUserInfo(data.phone, data.access_key);
    }
  };

  handleError = (e) => {
    message.error(e.message);
  };

  handleFormSubmit = (values) => {
    this.getUserInfo(
      `${values.prefix}${values.phone}`,
      values["access_key"],
      values["note"]
    );
  };

  onClose = () => {
    this.setState({
      codeLoading: false,
      modalVisibility: false,
      phone: "",
      currentUserName: "",
      email: "",
      company: "",
      clp: "",
      dob: null,
      extras: [],
    });
  };

  startSession = () => {
    let data = {
      scanner_id: Cookies.get("scanner_id"),
      phone: this.state.phone,
      access_key: this.state.accessKey,
      extras: this.extFormRef.current.getFieldsValue(),
      note: this.noteFormRef.current.getFieldValue("note"),
      flagged: this.state.flagged,
      company_id: this.state.companyId,
    };

    this.setState({
      scanning: true,
    });
    fetch(
      `  ${process.env.REACT_APP_API_URL}/sites/${Cookies.get(
        "site_id"
      )}/visits`,
      {
        method: "POST",
        body: JSON.stringify(data),
        headers: {
          Authorization: `Bearer ${Cookies.get("token")}`,
        },
      }
    )
      .then((res) => {
        if (res.status !== 200) {
          throw new Error(res.statusText);
        } else {
          return res.json();
        }
      })
      .then(() => {
        this.formRef.current.resetFields();
        this.noteFormRef.current.resetFields();
        this.extFormRef.current.resetFields();
        message.success("Session Started");
        this.onClose();
      })
      .catch((e) => {
        message.error(e.message);
      })
      .finally(
        this.setState({
          scanning: false,
        })
      );
  };

  render() {
    return (
      <div className="scanner-container">
        <Card
          className="scanner-card"
          title={
            <Title level={4} style={{ textAlign: "center" }}>
              Entrance Form
            </Title>
          }
        >
          <Spin
            spinning={this.state.codeLoading}
            indicator={<LoadingOutlined />}
          >
            <Form onFinish={this.handleFormSubmit} ref={this.formRef}>
              <Form.Item
                name="phone"
                rules={[
                  { required: true, message: "Phone number is required" },
                  PhoneValidator(),
                ]}
              >
                <Input
                  addonBefore={prefixSelector}
                  style={{ width: "100%" }}
                  placeholder="Phone number"
                />
              </Form.Item>
              <Form.Item
                name="access_key"
                rules={[
                  { required: true, message: "Access key is required" },
                  { len: 4, message: "Access key is only 4 digits" },
                  () => ({
                    validator(rule, value) {
                      if (!isNaN(value)) {
                        return Promise.resolve();
                      }
                      return Promise.reject(
                        "Access key can only contain numbers (0-9)."
                      );
                    },
                  }),
                ]}
              >
                <Input placeholder="Access Key" />
              </Form.Item>
              <Form.Item>
                <Button htmlType="submit">Get Info</Button>
              </Form.Item>
              <p>or</p>
              <Link to="/scanner/entry/qr">
                <Button icon={<QrcodeOutlined />}>Scan QR</Button>
              </Link>
            </Form>
          </Spin>
        </Card>

        <Route
          path="/scanner/entry/qr"
          render={() => (
            <ScannerScanModal
              loading={this.state.codeLoading}
              onScan={this.handleScan}
              onError={this.handleError}
              source="/scanner/entry"
            />
          )}
        />
        <Modal
          title={this.state.currentUserName}
          closable={true}
          onCancel={this.onClose}
          visible={this.state.modalVisibility}
          footer={null}
        >
          <Spin spinning={this.state.scanning}>
            <p>{`ID Number: ${
              this.state.id_number !== undefined ? this.state.id_number : "-"
            }`}</p>
            <p>{`Phone Number: ${
              this.state.phone !== undefined ? this.state.phone : "-"
            }`}</p>
            {/* <p>{`Email: ${
              this.state.email !== undefined ? this.state.email : "-"
            }`}</p> */}
            <p>{`Company: ${
              this.state.company !== undefined ? this.state.company : "-"
            }`}</p>
            <p>{`Car License Plate: ${
              this.state.clp !== undefined ? this.state.clp : "-"
            }`}</p>
            {/* <p>{`Date of Birth: ${
              this.state.dob !== undefined ? this.state.dob : "-"
            }`}</p> */}
            <Form
              ref={this.extFormRef}
              key={String(this.state.modalVisibility)}
            >
              <List
                dataSource={this.state.extras}
                renderItem={(item) => (
                  <Form.Item
                    style={{ marginBottom: "20px" }}
                    initialValue={item.value}
                    name={item.id}
                    help={item.description}
                    rules={[
                      {
                        required: true,
                        message: `${item.name} is required.`,
                      },
                      {
                        type: item.type === "text" ? "string" : "number",
                        message: `${item.name} should be a ${item.type}`,
                      },
                    ]}
                  >
                    <Input placeholder={item.name} />
                  </Form.Item>
                )}
              />

              <p style={{ textAlign: "center" }} />
            </Form>
            <Select
              showSearch
              onChange={this.onChange}
              disabled={
                !(
                  this.state.companies !== undefined &&
                  this.state.companies !== null &&
                  this.state.companies.length > 0
                )
              }
              defaultValue={
                this.state.companies !== undefined &&
                this.state.companies !== null &&
                this.state.companies.length > 0
                  ? this.state.companies[0].id
                  : ""
              }
              style={{ width: 200 }}
              placeholder="Select a Company"
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {this.state.companyOptions}
            </Select>
            <p style={{ color: "#00000073" }}>
              Select the name of the company the visitor wants to visit.
            </p>
            <br />
            <Form ref={this.noteFormRef}>
              <Form.Item name="note" help="Scanners note regarding the entry">
                <Input.TextArea placeholder="Scanner's Note" />
              </Form.Item>
              <span>
                <b>Flagged&nbsp;&nbsp;</b>
              </span>
              <Switch
                checked={this.state.flagged}
                onChange={(checked) =>
                  this.setState({
                    flagged: checked,
                  })
                }
              />
            </Form>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Button
                icon={<CheckOutlined />}
                type="primary"
                shape="circle"
                size="large"
                style={{
                  backgroundColor: "#52c41a",
                  outlineColor: "#52c41a",
                  borderColor: "#52c41a",
                }}
                loading={this.state.scanning}
                onClick={this.startSession}
              />
            </div>
          </Spin>
        </Modal>
      </div>
    );
  }
}
