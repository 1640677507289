import React from "react";
import {Card, message, Switch} from "antd";
import Cookies from "js-cookie";
import PropTypes from "prop-types";

export default function SiteScannerDetail({entry, exit, ...props}) {
    const valueChanged = (val, key) => {
        const site_id = Cookies.get("site_id");
        let data = {
            entry: key === 0 ? val : props.entry,
            exit: key === 1 ? val : props.exit,
        };
        let url = `  ${process.env.REACT_APP_API_URL}/sites/${site_id}/scanners/${props.id}`;
        fetch(url, {
            body: JSON.stringify(data),
            method: "PUT",
            headers: {
                Authorization: `Bearer ${Cookies.get("token")}`,
            },
        })
            .then((res) => {
                if (res.status !== 200) {
                    message.error("Update failed.");
                    throw new Error(res.statusText);
                } else {
                    return res.json();
                }
            })
            .then(() => {
                message.success("Update successful.");
            })
            .catch((e) => {
                message.error(e.message);
            });
    };
    return (
        <Card bordered={false} title={props.fullName}>
            <p>{`Phone Number: ${props.phone}`}</p>
            <p>{`Email: ${props.email}`}</p>
            <p>
                Entry:&nbsp;{" "}
                {
                    <Switch
                        defaultChecked={entry}
                        onChange={(checked, _) => {
                            valueChanged(checked, 0);
                        }}
                    />
                }
            </p>
            <p>
                Exit:&nbsp;{" "}
                {
                    <Switch
                        defaultChecked={exit}
                        onChange={(checked, _) => {
                            valueChanged(checked, 1);
                        }}
                    />
                }
            </p>
        </Card>
    );
}

SiteScannerDetail.propTypes = {
    entry: PropTypes.bool,
    exit: PropTypes.bool,
};
