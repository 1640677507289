import React, {useState} from "react";
import Modal from "antd/lib/modal/Modal";
import {withRouter} from "react-router-dom";
import QrScanner from "./QrScanner";
import {message} from "antd";

function ScannerLoginModal({onFinish, history}) {
    const [codeLoading, setCodeLoading] = useState(false);

    function hideQrModal() {
        setCodeLoading("");
        history.push("/scanner/auth");
    }

    function handleScan(data) {
        if (data != null) {
            onFinish(data);
            hideQrModal();
        }
    }

    function handleError(e) {
        message.error(e.message);
    }

    return (
        <Modal
            visible={true}
            closable
            onCancel={() => {
                hideQrModal();
            }}
            style={{
                display: "flex",
                justifyContent: "center",
                textAlign: "center",
            }}
            footer={null}
        >
            <QrScanner onScan={handleScan} onError={handleError} loading={codeLoading}/>
        </Modal>
    );
}

export default withRouter(ScannerLoginModal);
