import React, {useEffect, useState} from "react";
import {Menu} from "antd";
import {LoginOutlined, LogoutOutlined, PoweroffOutlined, SettingOutlined,} from "@ant-design/icons";
import {Link, Route, useHistory, useLocation} from "react-router-dom";
import ScannerEntryScanner from "./ScannerEntryScanner";
import ScannerExitScanner from "./ScannerExitScanner";
import SubMenu from "antd/lib/menu/SubMenu";
import Cookies from "js-cookie";

export default function ScannerHomepage() {
  const history = useHistory();
  const location = useLocation();

  const [selectedKey, setSelectedKey] = useState(location.pathname);

  const center = {display: "flex", justifyContent: "center"};
  const logout = () => {
    Cookies.remove("as");
    Cookies.remove("site_id");
    Cookies.remove("scanner_id");
    Cookies.remove("user_id")
    history.push("/scanner/login");
  };

  useEffect(() => {
    const getSelectedKey = () => {
      return location.pathname;
    };
    setSelectedKey(getSelectedKey());
  }, [location.pathname]);

  return (
      <div>
          <Menu mode="horizontal" multiple={false} style={center} selectedKeys={selectedKey}>
              <Menu.Item icon={<LoginOutlined/>} key="/scanner/entry">
                  <Link to="/scanner/entry"/>{" "}
              </Menu.Item>
              <Menu.Item icon={<LogoutOutlined/>} key="/scanner/exit">
                  <Link to="/scanner/exit"/>{" "}
              </Menu.Item>
              <SubMenu icon={<SettingOutlined/>}>
                  <Menu.Item key="logout" icon={<PoweroffOutlined/>} onClick={logout}>
                      Log out
                  </Menu.Item>
          </SubMenu>
        </Menu>
        <Route
            path="/scanner/entry"
            render={() => <ScannerEntryScanner/>}
        />
        <Route path="/scanner/exit" render={() => <ScannerExitScanner/>}/>
        <Route
            path="/scanner/"
            exact
            render={() => <ScannerEntryScanner/>}
        />
      </div>
  );
}
