import React, { useEffect, useState } from "react";
import { Layout, Menu } from "antd";
import Icon, {
  EditOutlined,
  FormOutlined,
  HistoryOutlined,
  InfoOutlined,
  LogoutOutlined,
  QrcodeOutlined,
  SettingOutlined,
  UserOutlined,
} from "@ant-design/icons";
import "../css/site-homepage.css";
import SiteScannersPage from "./SiteScannersPage";
import SiteCompaniesPage from "./SiteCompaniesPage";
import { Link, Route, Switch, useHistory, useLocation } from "react-router-dom";
import { enquireScreen } from "enquire-js";
import Cookies from "js-cookie";
import SiteVisitsPage from "./SiteVisitsPage";
import SiteEditInfo from "./SiteEditInfo";
import SiteInfoPage from "./SiteInfoPage";
import SiteExtrasPage from "./SiteExtrasPage";
import { BuildingSvg } from "../icons/svgs";
import logo from "../images/logo.png";

import SiteUsersPage from "./SiteUsersPage";
import SiteUnregisteredUserVisitsPage from "./SiteUnregisteredUserVisitsPage";

const { Content, Sider } = Layout;
const { SubMenu } = Menu;

export default function SiteHomepage() {
  const history = useHistory();
  const location = useLocation();

  const [isMobile, setIsMobile] = useState();
  const [collapsed, setCollapsed] = useState(false);
  const [selectedKey, setSelectedKey] = useState(location.pathname);

  const infoPage = <SiteInfoPage />;

  const visitsPage = <SiteVisitsPage />;
  
  const unregisteredUserVisitsPage = <SiteUnregisteredUserVisitsPage />;

  const companiesPage = <SiteCompaniesPage />;

  const scannersPage = <SiteScannersPage />;

  const extrasPage = <SiteExtrasPage />;

  const usersPage = <SiteUsersPage />;

  const editProfilePage = <SiteEditInfo />;

  const logout = () => {
    Cookies.remove("as");
    Cookies.remove("site_id");
    Cookies.remove("site_code");
    Cookies.remove("scanner_id");
    Cookies.remove("user_id");
    history.push("/login");
  };

  const onCollapse = (collapsed) => {
    setCollapsed(collapsed);
  };
  const collapse = () => {
    if (document.documentElement.clientWidth < 768) {
      setCollapsed(true);
    }
  };

  var collapsedWidth = 80;
  if (document.documentElement.clientWidth < 768) {
    collapsedWidth = 0;
  }

  useEffect(() => {
    const getSelectedKey = () => {
      return location.pathname;
    };
    setSelectedKey(getSelectedKey());
  }, [location.pathname]);

  useEffect(() => {
    enquireScreen((b) => {
      setIsMobile({ isMobile: !!b });
    });
  }, []);

  return (
    <Layout>
      <Sider
        collapsible
        collapsed={collapsed}
        collapsedWidth={collapsedWidth}
        onCollapse={onCollapse}
        breakpoint="sm"
        theme="light"
        style={{ paddingTop: 20 }}
      >
        <a href="https://qribu.netlify.app">
          <img
            alt="logo"
            className="logo"
            style={{
              height: isMobile ? "30px" : " 50px",
              textAlign: isMobile ? "center" : "left",
              opacity: " 1",
            }}
            src={logo}
          />
        </a>
        <Menu
          mode="inline"
          style={{}}
          selectedKeys={selectedKey}
          multiple={false}
          onSelect={collapse}
          onDeselect={() => {}}
        >
          <Menu.Item key="/info" icon={<InfoOutlined />}>
            Info
            <Link to="/info" />
          </Menu.Item>
          <Menu.Item
            key="/visits"
            icon={<HistoryOutlined />}
            onDeselect={() => {}}
          >
            Visits
            <Link to="/visits" />
          </Menu.Item>
          <Menu.Item
            key="/unregistered-user-visits"
            icon={<HistoryOutlined />}
            onDeselect={() => {}}
          >
            Unregistered User Visits
            <Link to="/unregistered-user-visits" />
          </Menu.Item>
          <Menu.Item key="companies" icon={<Icon component={BuildingSvg} />}>
            Companies
            <Link to="/companies" />
          </Menu.Item>
          <Menu.Item key="/scanners" icon={<QrcodeOutlined />}>
            Scanners
            <Link to="/scanners" />
          </Menu.Item>
          <Menu.Item key="/users" icon={<UserOutlined />}>
            Users
            <Link to="/users" />
          </Menu.Item>
          <Menu.Item key="/extras" icon={<FormOutlined />}>
            Extras
            <Link to="/extras" />
          </Menu.Item>
          <SubMenu icon={<SettingOutlined />} title="Account">
            <Menu.Item key="/edit" icon={<EditOutlined />}>
              Edit Site Info
              <Link to="/edit" />
            </Menu.Item>
            <Menu.Item key="log-out" icon={<LogoutOutlined />} onClick={logout}>
              Log out
            </Menu.Item>
          </SubMenu>
        </Menu>
      </Sider>
      <Layout className="site-layout">
        <Content style={{ padding: "16px" }} className="site-layout-background">
          <div
            className="site-layout-background"
            style={{ padding: 24, minHeight: 360 }}
          >
            <Switch>
              <Route path="/info" exact render={() => infoPage} />
              <Route path="/visits" exact render={() => visitsPage} />
              <Route path="/unregistered-user-visits" exact render={() => unregisteredUserVisitsPage} />
              <Route path="/companies" exact render={() => companiesPage} />
              <Route path="/scanners" exact render={() => scannersPage} />
              <Route path="/users" exact render={() => usersPage} />
              <Route path="/extras" exact render={() => extrasPage} />
              <Route path="/edit" render={() => editProfilePage} />
            </Switch>
          </div>
        </Content>
      </Layout>
    </Layout>
  );
}
