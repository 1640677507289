import React from "react";
import Modal from "antd/lib/modal/Modal";
import {useHistory} from "react-router-dom";
import QrScanner from "./QrScanner";

export default function ScannerScanModal(props) {
    const history = useHistory();
    const center = {display: "flex", justifyContent: "center"};

    const hideQrModal = () => {
        history.push(`${props.source}`);
    };
    return (
        <Modal
            visible={true}
            closable
            onCancel={() => {
                hideQrModal();
            }}
            style={{
                display: "flex",
                justifyContent: "center",
                textAlign: "center",
            }}
            footer={null}
        >
            <div style={center}>
                <QrScanner
                    onScan={(data) => {
                        if (data != null) {
                            props.onScan(data);
                            hideQrModal();
                        }
                    }}
                    onError={props.onError}
                    loading={props.loading}
                />
            </div>
        </Modal>
    );
}
