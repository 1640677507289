import React, { useState, useEffect } from "react";
import { Button, PageHeader } from "antd";
import { Link } from "react-router-dom";
import { QrcodeOutlined } from "@ant-design/icons";

import logo from "../images/logo.png";
import { enquireScreen } from "enquire-js";

export default function NavbarUnAuth(props) {
  const [isMobile, setIsMobile] = useState();
  useEffect(() => {
    enquireScreen((b) => {
      setIsMobile({ isMobile: !!b });
    });
  }, []);

  return (
    <PageHeader
      ghost={true}
      title={
        <Link to="/">
          <img
            alt="logo"
            className="logo"
            style={{
              height: isMobile ? "30px" : " 50px",
              margin: " 5px !important",
              opacity: " 1",
            }}
            src={logo}
          />
        </Link>
      }
      extra={[
        <Link to="/scanner/auth" key="scanner">
          <Button
            icon={<QrcodeOutlined />}
            type="link"
            className="menu-item"
            style={{ color: "#1bd313" }}
          >
            Scanner
          </Button>
        </Link>,
      ]}
    />
  );
}
