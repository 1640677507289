import React, { useEffect, useState } from "react";
import { Button, Checkbox, Form, Input, message, Spin, Tooltip } from "antd";
import TextArea from "antd/lib/input/TextArea";
import { Link, Route, useHistory } from "react-router-dom";
import Cookies from "js-cookie";

import "../css/site-edit.css";
import { EyeInvisibleOutlined } from "@ant-design/icons";
import PhoneValidator from "../functions/PhoneValidator";
import SpaceCheckValidator from "../functions/SpaceCheckValidator";
import ChangePassword from "./ChangePassword";
import { prefixSelector } from "./PhoneNumberInput";

export default function SiteEditInfo() {
  const [isLoadingSiteInfo, setIsLoadingSiteInfo] = useState(true);
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  let history = useHistory();

  const formRef = React.createRef();
  // const AutoCompleteOption = AutoComplete.Option;
  const onFinish = (values) => {
    setIsLoading(true);
    let url = `${process.env.REACT_APP_API_URL}/sites/${Cookies.get(
      "site_id"
    )}`;
    let data = {
      name: values["name"],
      code: values["code"],
      email: values["email"],
      phone_numbers: [`${values.prefix}${values.phone}`],
      description: values["description"],
      admin_username: values["admin_username"],
      ussd: values["ussd"],
    };
    fetch(url, {
      method: "PUT",
      body: JSON.stringify(data),
      headers: {
        Authorization: `Bearer ${Cookies.get("token")}`,
      },
    })
      .then((res) => {
        setIsLoading(false);
        if (res.status !== 200) {
          setIsError(true);
          throw new Error(res.statusText);
        } else {
          return res.json();
        }
      })
      .then(() => {
        history.push("/info");
      })
      .catch((e) => {
        message.error(e.message);
      });
  };

  useEffect(() => {
    const getSite = () => {
      let url = `${process.env.REACT_APP_API_URL}/sites?id=${Cookies.get(
        "site_id"
      )}`;
      fetch(url, {
        headers: {
          Authorization: `Bearer ${Cookies.get("token")}`,
        },
      })
        .then((res) => {
          if (res.status !== 200) {
            setIsLoadingSiteInfo(false);
            setIsError(true);
            throw new Error(res.statusText);
          } else {
            return res.json();
          }
        })
        .then((res) => {
          //   let d = new Date(res.dob).toDateString();
          if (formRef.current != null)
            formRef.current.setFieldsValue({
              name: res.name,
              code: res.code,
              admin_username: res.admin_username,
              email: res.email,
              phone: res.phone_numbers[0].slice(4),
              prefix: res.phone_numbers[0].slice(0, 4),
              description: res.description,
              ussd: res.ussd !== undefined && res.ussd !== false,
            });
          setIsLoadingSiteInfo(false);
        })
        .catch((e) => {
          message.error(e.message);
          setIsLoadingSiteInfo(false);
        });
    };
    getSite();
  }, [formRef]);

  return (
    <div className="site-editor-container">
      <Form
        name="register"
        onFinish={onFinish}
        scrollToFirstError
        className="site-editor"
        ref={formRef}
      >
        <Spin spinning={isLoadingSiteInfo}>
          <Tooltip title="Enter the name by which your site is referred to.">
            <Form.Item
              name="name"
              rules={[
                {
                  required: true,
                  message: "Please enter the name of the site.",
                },
              ]}
            >
              <Input placeholder="Name of the site" />
            </Form.Item>
          </Tooltip>

          <Tooltip title="Enter a code to be used by users when entering your site.">
            <Form.Item
              extra={
                <p
                  style={{
                    paddingLeft: 10,
                    paddingRight: 10,
                    paddingTop: 7.5,
                    lineHeight: "1rem",
                  }}
                >
                  <small>
                    <i>
                      *We advise you to use a simplified version of your site's
                      name to make entrance to your site easier.
                    </i>
                  </small>
                </p>
              }
              name="code"
              rules={[
                SpaceCheckValidator(),
                {
                  required: true,
                  message: "Please enter a code.",
                },
                {
                  min: 6,
                  message: "Your code should be at least 6 characters long",
                },
              ]}
            >
              <Input placeholder="Site short code" />
            </Form.Item>
          </Tooltip>

          <Tooltip title="Enter a short description (max 300 characters) about the site. (Optional)">
            <Form.Item
              name="description"
              rules={[
                {
                  max: 300,
                  message:
                    "The description should not be longer than 300 characters.",
                },
              ]}
            >
              <TextArea placeholder="Description" />
            </Form.Item>
          </Tooltip>

          <Tooltip title="Enter the primary email address your site uses.">
            <Form.Item
              name="email"
              rules={[
                {
                  type: "email",
                  message: "The input is not a valid email.",
                },
                {
                  required: true,
                  message: "Please input an email.",
                },
              ]}
            >
              <Input placeholder="Email" />
            </Form.Item>
          </Tooltip>

          <Tooltip title="Enter a unique username to be used by the admin for logging in.">
            <Form.Item
              name="admin_username"
              rules={[
                {
                  required: true,
                  message: "Please enter a username.",
                },
              ]}
            >
              <Input placeholder="Admin Username" />
            </Form.Item>
          </Tooltip>
          <Form.Item>
            <Link to="/edit/password">
              <Button style={{ width: "100%" }} icon={<EyeInvisibleOutlined />}>
                Change Password
              </Button>
            </Link>
          </Form.Item>
          <Form.Item name="ussd" valuePropName="checked">
            <Checkbox>Support USSD Scanner</Checkbox>
          </Form.Item>

          <Tooltip title="Enter the primary phone number used by the site.">
            <Form.Item
              name="phone"
              rules={[
                {
                  required: true,
                  message: "Please input a phone number.",
                },
                PhoneValidator(),
              ]}
            >
              <Input
                addonBefore={prefixSelector}
                style={{ width: "100%" }}
                placeholder="Phone number"
              />
            </Form.Item>
          </Tooltip>

          {isError ? (
            <p style={{ color: "red" }}>
              Some error has occurred. Please try again.
            </p>
          ) : (
            ""
          )}
          <Form.Item style={{ textAlign: "center" }}>
            <Button type="primary" htmlType="submit" loading={isLoading}>
              Save Changes
            </Button>
          </Form.Item>
        </Spin>
      </Form>
      <Route
        path="/edit/password"
        exact
        render={() => (
          <ChangePassword
            id={Cookies.get("site_id")}
            urlPath={`${process.env.REACT_APP_API_URL}/sites/${Cookies.get(
              "site_id"
            )}/password`}
            source="/edit"
          />
        )}
      />
    </div>
  );
}
