const SpaceCheckValidator = () => ({
    validator(rule, value) {
        if (String(value).search(" ") <= 0) {
            return Promise.resolve();
        }
        return Promise.reject("Value should not have a space.");
    },
});

export default SpaceCheckValidator;
  