import React, { Component } from "react";
import {
  Button,
  Card,
  Checkbox,
  Col,
  Drawer,
  Form,
  Input,
  List,
  message,
  Popconfirm,
  Row,
  Tooltip,
} from "antd";
import {
  CheckCircleOutlined,
  DeleteOutlined,
  InfoCircleOutlined,
  StopOutlined,
  WarningOutlined,
} from "@ant-design/icons";

import "../css/site-scanners.css";
import Cookies from "js-cookie";
import PhoneValidator from "../functions/PhoneValidator";
import SiteScannerDetail from "./SiteScannerDetail";
import { getDrawerPlacement } from "../functions/ResponsiveVariables";
import { prefixSelector } from "./PhoneNumberInput";

export default class SiteScannersPage extends Component {
  state = {
    initLoading: true,
    loading: false,
    data: [],
    list: [],
    drawerVisibility: false,
    errorMessage: "",
    successMessage: "",
    scannerInfo: {},
    submitLoading: false,
  };

  formRef = React.createRef();

  getData = async () => {
    const site_id = Cookies.get("site_id");

    let url = `  ${process.env.REACT_APP_API_URL}/sites?id=${site_id}`;
    fetch(url, {
      headers: {
        Authorization: `Bearer ${Cookies.get("token")}`,
      },
    })
      .then((res) => {
        if (res.status !== 200) {
          throw new Error(res.statusText);
        } else {
          return res.json();
        }
      })
      .then((res) => {
        this.setState({
          initLoading: false,
          data: res.scanners,
          list: res.scanners,
        });
      })
      .catch((e) => {
        message.error(e.message);
      });
  };

  showDrawer = (item, e) => {
    e.preventDefault();
    fetch(`${process.env.REACT_APP_API_URL}/users?id=${item.user_id}`, {
      headers: {
        Authorization: `Bearer ${Cookies.get("token")}`,
      },
    })
      .then((res) => res.json())
      .then((res) => {
        this.setState({
          scannerInfo: { ...res, ...item },
        });
      })
      .catch((e) => {
        message.error(e.message);
        this.setState({
          scannerInfo: {},
        });
      });
    this.setState({
      drawerVisibility: true,
    });
  };
  onClose = () => {
    this.setState({
      drawerVisibility: false,
    });
  };

  confirm = (id, current) => {
    const site_id = Cookies.get("site_id");
    this.setState({
      submitLoading: true,
    });
    let data = {
      permitted: !current,
    };
    let url = `  ${process.env.REACT_APP_API_URL}/sites/${site_id}/scanners/${id}`;
    fetch(url, {
      body: JSON.stringify(data),
      method: "PUT",
      headers: {
        Authorization: `Bearer ${Cookies.get("token")}`,
      },
    })
      .then((res) => {
        this.setState({ submitLoading: false });
        if (res.status !== 200) {
          message.error(current ? "Ban failed." : "Removing ban failed");
          throw new Error(res.statusText);
        } else {
          this.getData();
          return res.json();
        }
      })
      .then(() => {
        message.success(
          current ? "Ban successful." : "Ban successfully removed."
        );
        this.formRef.current.resetFields();
      })
      .catch((e) => {
        message.error(e.message);
      });
  };
  deleteScanner = (id) => {
    const site_id = Cookies.get("site_id");
    let url = `  ${process.env.REACT_APP_API_URL}/sites/${site_id}/scanners/${id}`;
    fetch(url, {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${Cookies.get("token")}`,
      },
    })
      .then((res) => {
        this.setState({ submitLoading: false });
        if (res.status !== 200) {
          message.error("Deletion failed.");
          throw new Error(res.statusText);
        } else {
          this.getData();
          return res.json();
        }
      })
      .then(() => {
        message.success("Deletion successful.");
        this.formRef.current.resetFields();
      })
      .catch((e) => {
        message.error(e.message);
      });
  };

  cancel = () => {
    message.error("Canceled!");
  };

  submit = (values) => {
    const site_id = Cookies.get("site_id");
    this.setState({
      submitLoading: true,
    });
    let data = {
      username: values["username"],
      password: values["password"],
      phone_number: `${values.prefix}${values.phone}`,
      permitted: true,
      entry: values["entry"],
      exit: values["exit"],
    };
    let url = `  ${process.env.REACT_APP_API_URL}/sites/${site_id}/scanners`;
    fetch(url, {
      body: JSON.stringify(data),
      method: "POST",
      headers: {
        Authorization: `Bearer ${Cookies.get("token")}`,
      },
    })
      .then((res) => {
        this.setState({ submitLoading: false });
        if (res.status !== 200) {
          this.setState({
            errorMessage:
              "User doesn't have an account or is already added as a scanner.",
            successMessage: "",
          });
          throw new Error(res.statusText);
        } else {
          this.getData();
          return res.json();
        }
      })
      .then(() => {
        this.setState({
          errorMessage: "",
          successMessage: "Done!",
        });
        this.formRef.current.resetFields();
      })
      .catch((e) => {
        message.error(e.message);
      });
  };

  componentDidMount() {
    this.getData();
  }

  render() {
    return (
      <>
        <div className="container-div">
          <div className="left-div">
            <Card
              className="left-card"
              bordered={false}
              style={{ width: "90%" }}
            >
              <div className="scrollable-div shorter-div">
                <List
                  className="demo-loadmore-list"
                  loading={this.state.initLoading}
                  itemLayout="horizontal"
                  dataSource={this.state.list}
                  renderItem={(item) => (
                    <List.Item>
                      {/* <Skeleton avatar title={false} loading={false} active> */}
                      <List.Item.Meta
                        title={
                          <span
                            className="fake-link"
                            onClick={() => this.showDrawer(item)}
                          >
                            {item.username}
                          </span>
                        }
                        description={item.permitted ? "Permitted" : "Banned"}
                      />
                      <div style={{ display: "flex", width: "300px" }}>
                        <div style={{ flexGrow: 2 }} />
                        <Popconfirm
                          title={
                            item.permitted
                              ? "Do you really want to ban this scanner?"
                              : "Do you really want to remove the ban from this scanner?"
                          }
                          onConfirm={() =>
                            this.confirm(item.id, item.permitted)
                          }
                          onCancel={this.cancel}
                          okText="Yes"
                          cancelText="No"
                          icon={<WarningOutlined style={{ color: "red" }} />}
                        >
                          <Button
                            icon={
                              item.permitted ? (
                                <StopOutlined style={{ color: "red" }} />
                              ) : (
                                <CheckCircleOutlined
                                  style={{ color: "green" }}
                                />
                              )
                            }
                            type="ghost"
                          />
                        </Popconfirm>

                        <div style={{ flexGrow: 1 }} />
                        <Popconfirm
                          title={"Do you really want to delete this scanner?"}
                          onConfirm={() => this.deleteScanner(item.id)}
                          onCancel={this.cancel}
                          okText="Yes"
                          okType="danger"
                          cancelText="No"
                          icon={<DeleteOutlined style={{ color: "red" }} />}
                        >
                          <Button
                            icon={<DeleteOutlined style={{ color: "red" }} />}
                            type="ghost"
                          />
                        </Popconfirm>
                        <div style={{ flexGrow: 1 }} />

                        <Button
                          type="link"
                          icon={
                            <InfoCircleOutlined
                              onClick={(e) => {
                                this.showDrawer(item, e);
                              }}
                            />
                          }
                        />

                        <div style={{ flexGrow: 2 }} />
                      </div>
                      <div>
                        {/*
                          <Row gutter={20}>
                            <Tooltip title="Lifetime scans">
                              <Col span={4}>LS</Col>
                            </Tooltip>
                            <Col span={16}>
                              <span>{item.lifetime_scans}</span>
                            </Col>
                          </Row>
                          <Row gutter={20}>
                            <Tooltip title="Scans today">
                              <Col span={4}>ST</Col>
                            </Tooltip>
                            <Col span={16}>
                              <Badge
                                count={
                                  <CaretUpFilled style={{ color: "green" }} />
                                }
                                offset={[10, 10]}
                              >
                                <span>{item.scans_today}</span>
                              </Badge>
                            </Col>
                          </Row> */}
                      </div>
                      {/* </Skeleton> */}
                    </List.Item>
                  )}
                />
              </div>
            </Card>
          </div>

          <div className="right-div">
            <Card title="Add Scanner">
              <Form
                name="register"
                onFinish={this.submit}
                scrollToFirstError
                // style={{ textAlign: "center" }}
                ref={this.formRef}
              >
                <Tooltip title="Enter the phone number of used by the user to sign up to our platform.">
                  <Form.Item
                    name="phone"
                    rules={[
                      {
                        required: true,
                        message: "Please input a phone number.",
                      },
                      PhoneValidator(),
                    ]}
                  >
                    <Input
                      addonBefore={prefixSelector}
                      style={{ width: "100%" }}
                      placeholder="Phone number"
                    />
                  </Form.Item>
                </Tooltip>
                <Tooltip title="Enter the desired username of the scanner.">
                  <Form.Item
                    name="username"
                    rules={[
                      {
                        required: true,
                        message: "Please enter a username",
                      },
                      {
                        min: 6,
                        message: "Username should be at least 6 characters",
                      },
                    ]}
                  >
                    <Input placeholder="Username" />
                  </Form.Item>
                </Tooltip>
                <Form.Item
                  name="password"
                  rules={[
                    {
                      required: true,
                      message: "Please enter a password",
                    },
                    {
                      min: 8,
                      message: "Password should be at least 8 characters",
                    },
                  ]}
                >
                  <Input.Password placeholder="Password" />
                </Form.Item>
                <Form.Item
                  name="entry"
                  initialValue={true}
                  valuePropName="checked"
                >
                  <Checkbox>Scanner can perform entries</Checkbox>
                </Form.Item>
                <Form.Item
                  name="exit"
                  initialValue={true}
                  valuePropName="checked"
                >
                  <Checkbox>Scanner can perform exits</Checkbox>
                </Form.Item>
                <p style={{ color: "red" }}>{this.state.errorMessage}</p>
                <p style={{ color: "green" }}>{this.state.successMessage}</p>
                <Row gutter={24} style={{ textAlign: "center" }}>
                  <Col span={24} style={{ textAlign: "center" }}>
                    <Form.Item>
                      <Button
                        type="primary"
                        htmlType="submit"
                        style={{ width: "100%" }}
                        loading={this.state.submitLoading}
                      >
                        Add
                      </Button>
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
            </Card>
            {/* <Card>
              <CompanySummary />
            </Card> */}
          </div>
        </div>
        <Drawer
          placement={getDrawerPlacement()}
          onClose={this.onClose}
          visible={this.state.drawerVisibility}
          width="35%"
          height="50%"
        >
          <SiteScannerDetail
            key={Date.now().toString()}
            footer={null}
            fullName={
              this.state.scannerInfo.name
                ? `${this.state.scannerInfo.name.first} ${
                    this.state.scannerInfo.name.middle || ""
                  } ${this.state.scannerInfo.name.last}`
                : "-"
            }
            phone={
              this.state.scannerInfo.phone_number
                ? this.state.scannerInfo.phone_number
                : "-"
            }
            email={
              this.state.scannerInfo.email ? this.state.scannerInfo.email : "-"
            }
            entry={Boolean(this.state.scannerInfo.entry)}
            exit={Boolean(this.state.scannerInfo.exit)}
            id={this.state.scannerInfo.id}
          />
        </Drawer>
      </>
    );
  }
}
