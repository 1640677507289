
import NavbarUnAuth from "./NavbarUnAuth";
import SiteLoginForm from "./SiteLoginForm";
import { Card } from "antd";

import "../css/company-auth.css";
import "../css/auth.css";
import SiteRegistrationForm from "./SiteRegistrationForm";
import { Redirect, Route, Switch } from "react-router-dom";

export default function SiteAuth() {
  const logInComponent = (
    <Card className="login-card" style={{ textAlign: "center" }}>
      <SiteLoginForm />
    </Card>
  );
  const registerComponent = (
    <Card
      className="fancy-scroll register-card"
      style={{ textAlign: "center", overflowY: "scroll" }}
    >
      <SiteRegistrationForm />
    </Card>
  );

  return (
    <div className="content">
      <NavbarUnAuth color="#3D8AF7"/>
      <div className="container">
        <Switch>
          <Route path="/" exact render={() => <Redirect to="/register" />} />
          <Route path="/login" render={() => logInComponent} />
          <Route path="/register" render={() => registerComponent} />
        </Switch>
      </div>
    </div>
  );
}
