import React, { useState } from "react";
import { Button, Checkbox, Form, Input, message, Tooltip } from "antd";
import TextArea from "antd/lib/input/TextArea";
import { Link, useHistory } from "react-router-dom";
import Cookies from "js-cookie";
import SpaceCheckValidator from "../functions/SpaceCheckValidator";
import PhoneValidator from "../functions/PhoneValidator";
import { prefixSelector } from "./PhoneNumberInput";

export default function SiteRegistrationForm() {
  const [isLoggedIn, setLoggedIn] = useState(false);
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  let history = useHistory();

  // const AutoCompleteOption = AutoComplete.Option;
  const onFinish = (values) => {
    setIsLoading(true);
    let url = `  ${process.env.REACT_APP_API_URL}/sites`;
    let data = {
      admin_username: values["username"],
      code: values["code"],
      admin_password: values["confirm"],
      name: values["name"],
      email: values["email"],
      phone_numbers: [`${values.prefix}${values.phone}`],
      description: values["description"],
    };
    fetch(url, {
      method: "POST",
      body: JSON.stringify(data),
    })
      .then((res) => {
        setIsLoading(false);
        if (res.status !== 200) {
          setIsError(true);
          throw new Error(res.statusText);
        } else {
          return res.json();
        }
      })
      .then((res) => {
        Cookies.set("site_id", res.id, { expires: 365 });
        Cookies.set("as", "site", { expires: 365 });
        Cookies.set("token", res.token, { expires: 365 });
        setLoggedIn(true);
      })
      .catch((e) => {
        message.error(e.message);
      });
  };
  if (isLoggedIn) {
    history.push("/info");
  }
  return (
    <Form onFinish={onFinish} scrollToFirstError>
      <Tooltip title="Enter the name by which your site is referred to.">
        <Form.Item
          name="name"
          rules={[
            {
              required: true,
              message: "Please enter the name of the site.",
            },
          ]}
        >
          <Input placeholder="Name of the site" />
        </Form.Item>
      </Tooltip>
      <Tooltip title="Enter a code to be used by users when entering your site.">
        <Form.Item
          extra={
            <p
              style={{
                paddingLeft: 10,
                paddingRight: 10,
                paddingTop: 7.5,
                lineHeight: "1rem",
              }}
            >
              <small>
                <i>
                  *We advise you to use a simplified version of your site's name
                  to make entrance to your site easier.
                </i>
              </small>
            </p>
          }
          name="code"
          rules={[
            SpaceCheckValidator(),
            {
              required: true,
              message: "Please enter a code.",
            },
            {
              min: 6,
              message: "Your code should be at least 6 characters long",
            },
          ]}
        >
          <Input placeholder="Site short code" />
        </Form.Item>
      </Tooltip>

      <Tooltip title="Enter a short description (max 300 characters) about the site. (Optional)">
        <Form.Item
          name="description"
          rules={[
            {
              max: 300,
              message:
                "The description should not be longer than 300 characters.",
            },
          ]}
        >
          <TextArea placeholder="Description" />
        </Form.Item>
      </Tooltip>

      <Tooltip title="Enter the primary email address your site uses.">
        <Form.Item
          name="email"
          rules={[
            {
              type: "email",
              message: "The input is not a valid email.",
            },
            {
              required: true,
              message: "Please input an email.",
            },
          ]}
        >
          <Input placeholder="Email" />
        </Form.Item>
      </Tooltip>

      <Tooltip title="Enter a unique username to be used by the admin for logging in.">
        <Form.Item
          name="username"
          rules={[
            {
              required: true,
              message: "Please enter a username.",
            },
          ]}
        >
          <Input placeholder="Admin Username" />
        </Form.Item>
      </Tooltip>

      <Form.Item
        name="password"
        rules={[
          {
            required: true,
            message: "Please input a password.",
          },
          {
            min: 8,
            message: "Your password should be at least 8 characters long.",
          },
        ]}
        hasFeedback
      >
        <Input.Password placeholder="Password" />
      </Form.Item>

      <Form.Item
        name="confirm"
        dependencies={["password"]}
        hasFeedback
        rules={[
          {
            required: true,
            message: "Please confirm your password.",
          },
          ({ getFieldValue }) => ({
            validator(rule, value) {
              if (!value || getFieldValue("password") === value) {
                return Promise.resolve();
              }
              return Promise.reject(
                "The two passwords that you entered do not match."
              );
            },
          }),
        ]}
      >
        <Input.Password placeholder="Confirm password" />
      </Form.Item>

      <Tooltip title="Enter the primary phone number used by the site.">
        <Form.Item
          name="phone"
          rules={[
            {
              required: true,
              message: "Please input a phone number.",
            },
            PhoneValidator(),
          ]}
        >
          <Input
            addonBefore={prefixSelector}
            style={{ width: "100%" }}
            placeholder="Phone number"
          />
        </Form.Item>
      </Tooltip>

      <Form.Item
        name="agreement"
        valuePropName="checked"
        rules={[
          {
            validator: (_, value) =>
              value
                ? Promise.resolve()
                : Promise.reject("Should accept agreement"),
          },
        ]}
      >
        <Checkbox>
          I have read and accepted the{" "}
          <a href="https://qribu.netlify.app">agreement</a>
        </Checkbox>
      </Form.Item>
      {isError ? (
        <p style={{ color: "red" }}>
          Some error has occurred. Please try again.
        </p>
      ) : (
        ""
      )}
      <Form.Item>
        <Button
          type="primary"
          htmlType="submit"
          size="middle"
          shape="round"
          loading={isLoading}
        >
          Register
        </Button>
      </Form.Item>
      <Link to="/login">Already have an account?</Link>
    </Form>
  );
}
