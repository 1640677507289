import React, { Component } from "react";
import {
  Button,
  Card,
  Drawer,
  Form,
  Input,
  List,
  message,
  Popconfirm,
  Skeleton,
  Spin,
  TimePicker,
} from "antd";
import {
  DeleteOutlined,
  RightOutlined,
  WarningOutlined,
} from "@ant-design/icons";
import Cookies from "js-cookie";
import "../css/site-companies.css";
import CompanyDetail from "./CompanyDetail";
import CompanySummary from "./CompanySummary";
import { getDrawerPlacement } from "../functions/ResponsiveVariables";
import PhoneValidator from "../functions/PhoneValidator";
import { prefixSelector } from "./PhoneNumberInput";

export default class SiteCompaniesPage extends Component {
  state = {
    initLoading: true,
    data: [],
    list: [],
    drawerVisibility: false,
    modalVisibility: false,
    modalLoading: false,
    company: {},
    regLoading: false,
  };

  formRef = React.createRef();

  onFinish = async (values) => {
    this.setState({ regLoading: true });
    const company = {
      site_id: Cookies.get("site_id"),
      name: values.name,
      description: values.description != null ? values.description : null,
      phone_numbers: [`${values.prefix}${values.phone}`],
      email: values.email,
      admin_username: values.admin_username,
      admin_password: values.admin_password,
      opening_time: values.opening_time,
      closing_time: values.closing_time,
    };
    fetch(`${process.env.REACT_APP_API_URL}/companies`, {
      method: "POST",
      body: JSON.stringify(company),
    })
      .then(async (res) => {
        if (res.statusCode < 200 && res.statusCode > 300) {
          const json = await res.json();
          throw new Error(json.message);
        }
      })

      .catch((e) => {
        message.error(e.message);
      })
      .finally(() => {
        this.setState({ regLoading: false });
        this.formRef.current.resetFields();
        this.updateCompaniesList();
      });
  };

  companyRegistrationForm = (
    <Form
      name="register"
      onFinish={this.onFinish}
      scrollToFirstError
      style={{ textAlign: "center" }}
      ref={this.formRef}
    >
      <Spin spinning={this.state.regLoading}>
        <Form.Item
          name="name"
          rules={[
            {
              required: true,
              message: "Please enter the name of the company.",
            },
          ]}
          extra={
            <small>
              <i style={{ color: "red" }}>*&nbsp;</i>
              Enter the name by which the company is referred to.
            </small>
          }
        >
          <Input placeholder="Name of the company" />
        </Form.Item>

        <Form.Item
          name="description"
          rules={[
            {
              max: 300,
              message:
                "The description should not be longer than 300 characters.",
            },
          ]}
          extra={
            <small>
              Enter a short description (max 300 characters) about the company.
            </small>
          }
        >
          <Input placeholder="Description" />
        </Form.Item>

        <Form.Item
          name="phone"
          rules={[
            {
              required: true,
              message: "Please input a phone number.",
            },
            PhoneValidator(),
          ]}
          extra={
            <small>
              <i style={{ color: "red" }}>*&nbsp;</i>
              Enter the phone number used by the company.
            </small>
          }
        >
          <Input
            addonBefore={prefixSelector}
            style={{ width: "100%" }}
            placeholder="Phone number"
          />
        </Form.Item>

        <Form.Item
          name="email"
          rules={[
            {
              type: "email",
              message: "The input is not a valid email.",
            },
            {
              required: true,
              message: "Please input an email.",
            },
          ]}
          extra={
            <small>
              <i style={{ color: "red" }}>*&nbsp;</i>
              Enter the primary email address the company uses.
            </small>
          }
        >
          <Input placeholder="Email" />
        </Form.Item>
        <Form.Item
          name="admin_username"
          rules={[
            {
              required: true,
              message: "Please enter a username.",
            },
          ]}
          extra={
            <small>
              <i style={{ color: "red" }}>*&nbsp;</i>
              Enter a unique username to be used by the company for logging in.
            </small>
          }
        >
          <Input placeholder="Admin Username" />
        </Form.Item>

        <Form.Item
          name="admin_password"
          rules={[
            {
              required: true,
              message: "Please input a password.",
            },
            {
              min: 8,
              message: "Your password should be at least 8 characters long.",
            },
          ]}
          extra={
            <small>
              <i style={{ color: "red" }}>*&nbsp;</i>
              Enter a password to be used by the company for logging in.
            </small>
          }
          hasFeedback
        >
          <Input.Password placeholder="Password" />
        </Form.Item>
        <Form.Item
          name="opening_time"
          valuePropName="picked"
          extra={
            <small>
              Enter the time the at which the company starts accepting visitors.
            </small>
          }
        >
          <TimePicker
            placeholder={"Pick opening time."}
            style={{ width: "100%" }}
          />
        </Form.Item>
        <Form.Item
          name="closing_time"
          valuePropName="picked"
          extra={
            <small>
              Enter the time the at which the company stops accepting visitors.
            </small>
          }
        >
          <TimePicker
            placeholder={"Pick closing time."}
            style={{ width: "100%" }}
          />
        </Form.Item>

        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            style={{ width: "100%" }}
            loading={this.state.regLoading}
          >
            Add
          </Button>
        </Form.Item>
      </Spin>
    </Form>
  );

  componentDidMount() {
    this.updateCompaniesList();
  }

  updateCompaniesList() {
    this.getData((res) => {
      this.setState({
        initLoading: false,
        data: res,
        list: res,
      });
    }).then();
  }

  getData = async (callback) => {
    const site_code = Cookies.get("site_code");
    const companiesUrl = `${process.env.REACT_APP_API_URL}/sites/${site_code}/companies`;
    await fetch(companiesUrl, {
      headers: {
        Authorization: `Bearer ${Cookies.get("token")}`,
      },
    })
      .then((res) => res.json())
      .then((res) => {
        if (res === null) {
          callback([]);
          return;
        }

        callback(res);
      });
  };
  onClose = () => {
    this.setState({
      drawerVisibility: false,
    });
  };

  showDrawer = (company) => {
    this.setState(
      {
        company: company,
      },
      () =>
        this.setState({
          drawerVisibility: true,
        })
    );
  };

  confirm = (company) => {
    const url = `${process.env.REACT_APP_API_URL}/companies/${company.id}`;
    fetch(url, {
      headers: {
        Authorization: `Bearer ${Cookies.get("token")}`,
      },
      method: "DELETE",
    })
      .then((res) => res.json())
      .then(() => {
        message.success("Deleted!");
        this.updateCompaniesList();
      })
      .catch((e) => message.error(e));
  };

  cancel = () => {
    message.warning("Canceled!");
  };

  render() {
    const { initLoading, list } = this.state;
    return (
      <>
        <div className="container-div">
          <div className={"left-div"}>
            <Card>
              <div className="scrollable-div shorter-div">
                <List
                  loading={initLoading}
                  itemLayout="horizontal"
                  dataSource={list}
                  renderItem={(item) => (
                    <List.Item
                      actions={[
                        <Button
                          icon={<RightOutlined />}
                          shape="round"
                          size="small"
                          onClick={() => this.showDrawer(item)}
                        />,
                        <Popconfirm
                          title="Are you sure delete this company?"
                          onConfirm={() => this.confirm(item)}
                          onCancel={this.cancel}
                          okText="Yes"
                          okType="danger"
                          cancelText="No"
                          icon={<WarningOutlined style={{ color: "red" }} />}
                        >
                          <Button
                            icon={<DeleteOutlined />}
                            size="small"
                            type="dashed"
                            danger
                          />
                        </Popconfirm>,
                      ]}
                    >
                      <Skeleton avatar title={false} loading={false} active>
                        <List.Item.Meta
                          // avatar={
                          //   <Avatar src="https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png" />
                          // }
                          title={item.name}
                          description={item.description}
                        />
                      </Skeleton>
                    </List.Item>
                  )}
                />
              </div>
            </Card>
          </div>

          <div className="right-div">
            <Card title="Add Company" style={{ marginBottom: 20 }}>
              {this.companyRegistrationForm}
            </Card>
            <Card>
              <CompanySummary count={list.length} />
            </Card>
          </div>
        </div>
        <Drawer
          placement={getDrawerPlacement()}
          onClose={this.onClose}
          visible={this.state.drawerVisibility}
          width="50%"
          height="50vh"
        >
          <CompanyDetail
            Edit={true}
            Name={this.state.company.name || "-"}
            Description={this.state.company.description || "-"}
            AdminUsername={this.state.company.admin_username || "-"}
            PhoneNumber={
              this.state.company.phone_numbers !== undefined
                ? this.state.company.phone_numbers[0]
                : "-"
            }
            Email={this.state.company.email || "-"}
            OpeningTime={new Date(this.state.company.opening_time) || "-"}
            ClosingTime={new Date(this.state.company.closing_time) || "-"}
          />
        </Drawer>
      </>
    );
  }
}
