import React from "react";
import {BrowserRouter as Router, Redirect, Route, Switch} from "react-router-dom";
import ScannerHomepage from "./components/ScannerHomepage";
import ScannerLogin from "./components/ScannerLogin";
import SiteAuth from "./components/SiteAuth";
import SiteHomepage from "./components/SiteHomepage";
import PrivateScannerRoute from "./PrivateScannerRoute";
import PrivateSiteRoute from "./PrivateSiteRoute";
import PublicRoute from "./PublicRoute";

export default function App() {
    return (
        <Router>
            <Switch>
                {/*site auth */}
                <Route path="/" exact render={() => <Redirect to="/visits"/>}/>
                <PublicRoute
                    path="/login"
                    exact
                    render={(props) => <SiteAuth {...props} />}
                />
                <PublicRoute
                    path="/register"
                    exact
                    render={(props) => <SiteAuth {...props} />}
                />

                {/*site operations */}
                <PrivateSiteRoute
                    path="/visits"
                    exact
                    render={(props) => <SiteHomepage {...props} />}
                />
                <PrivateSiteRoute
                    path="/unregistered-user-visits"
                    exact
                    render={(props) => <SiteHomepage {...props} />}
                />
                <PrivateSiteRoute
                    path="/info"
                    exact
                    render={(props) => <SiteHomepage {...props} />}
                />
                <PrivateSiteRoute
                    path="/companies"
                    exact
                    render={(props) => <SiteHomepage {...props} />}
                />
                <PrivateSiteRoute
                    path="/scanners"
                    exact
                    render={(props) => <SiteHomepage {...props} />}
                />
                <PrivateSiteRoute
                    path="/extras"
                    exact
                    render={(props) => <SiteHomepage {...props} />}
                />
                <PrivateSiteRoute
                    path="/users"
                    exact
                    render={(props) => <SiteHomepage {...props} />}
                />
                <PrivateSiteRoute
                    path="/edit"
                    exact
                    render={(props) => <SiteHomepage {...props} />}
                />
                <PrivateSiteRoute
                    path="/edit/password"
                    exact
                    render={(props) => <SiteHomepage {...props} />}
                />

                {/*Scanner auth */}
                <PublicRoute
                    path="/scanner/auth"
                    render={(props) => <ScannerLogin {...props} />}
                />
                <PublicRoute
                    path="/scanner/auth/code"
                    render={(props) => <ScannerLogin {...props} />}
                />

                {/*Scanner operations  */}
                <PrivateScannerRoute
                    path="/scanner/entry"
                    render={(props) => <ScannerHomepage {...props} />}
                />
                <PrivateScannerRoute
                    path="/scanner/exit"
                    render={(props) => <ScannerHomepage {...props} />}
                />
                <PrivateScannerRoute
                    path="/scanner"
                    render={() => <Redirect to="/scanner/entry"/>}
                />

                <Route path="*"
                       children={<h1 style={{textAlign: "center", paddingTop: "40vh"}}>404: Page not found</h1>}/>
            </Switch>
        </Router>
    );
}
