import React from "react";
import {Col, Row} from "antd";

export default function CompanySummary({count}) {

    return (
        <Col span={24}>
            <Row gutter={24}>
                <Col span={20} style={{fontWeight: 500}}>
                    Number of Companies
                </Col>
                <Col span={4}>{count}</Col>
            </Row>
            {/*<Link to="/">Show more insights</Link>*/}
        </Col>
    );

}
