import React, {useState} from "react";

import NavbarUnAuth from "./NavbarUnAuth";
import {Button, Card, Form, Input, message} from "antd";

import "../css/auth.css";
import {LockOutlined, QrcodeOutlined, UserOutlined} from "@ant-design/icons";
import ScannerLoginModal from "./ScannerLoginModal";
import {Link, Route, useHistory, useLocation} from "react-router-dom";
import Cookies from "js-cookie";

export default function ScannerLogin() {
    const [isLoggedIn] = useState(false);
    const [isError, setIsError] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    let history = useHistory();
    let location = useLocation();

    let {from} = location.state || {from: {pathname: "/scanner"}};

    const formRef = React.createRef();

    const submit = (values) => {
        setIsLoading(true);
        let url = `  ${process.env.REACT_APP_API_URL}/sites/${values["site_code"]}/scanners/login`;
        let data = {
            username: values["username"],
            password: values["password"],
        };
        fetch(url, {
            method: "POST",
            body: JSON.stringify(data),
        })
            .then((res) => {
                setIsLoading(false);
                if (res.status !== 200) {
                    setIsError(true);

                    throw new Error(res.statusText);
                } else {
                    return res.json();
                }
            })
            .then((res) => {
                Cookies.set("scanner_id", res.id, {expires: 365});
                Cookies.set("site_id", res.site_id, {expires: 365});
                Cookies.set("token", res.token, {expires: 365});
                Cookies.set("as", "scanner");
            })
            .catch((e) => {
                message.error(e.message);
                setIsLoading(false);
                setIsError(true);
            }).finally(() => {
            history.push(from);
        });
    };

    if (isLoggedIn) {
        history.push(from);
    }
    return (
        <>
            <div className="content">
                <NavbarUnAuth color="#2A71C3"/>
                <div className="container">
                    <Card className="login-card" style={{textAlign: "center"}}>
                        <Form name="login" onFinish={submit} ref={formRef}>
                            <Form.Item
                                name="site_code"
                                rules={[
                                    {
                                        required: true,
                                        message: "Please input the site's short code.",
                                    },
                                ]}
                            >
                                <Input
                                    style={{width: "100%"}}
                                    placeholder="Site Code"
                                    suffix={
                                        <Link to="/scanner/auth/code">
                                            <Button type="ghost" icon={<QrcodeOutlined/>}/>
                                        </Link>
                                    }
                                />
                            </Form.Item>
                            <Form.Item
                                name="username"
                                rules={[
                                    {
                                        required: true,
                                        message: "Please input the username you registered with.",
                                    },
                                    {
                                        min: 6,
                                        message: "Username is at least 6 characters long.",
                                    },
                                ]}
                            >
                                <Input
                                    prefix={<UserOutlined/>}
                                    style={{width: "100%"}}
                                    placeholder="Username"
                                />
                            </Form.Item>
                            <Form.Item
                                name="password"
                                rules={[
                                    {required: true, message: "Please input your password."},
                                ]}
                            >
                                <Input.Password
                                    prefix={<LockOutlined/>}
                                    placeholder="Password"
                                />
                            </Form.Item>
                            {isError ?
                                <p style={{color: "red"}}>
                                    The username or password provided were incorrect!
                                </p> : ""
                            }
                            <Form.Item>
                                <Button
                                    type="primary"
                                    // className="accent2-bg accent2-outline"
                                    htmlType="submit"
                                    size="middle"
                                    shape="round"
                                    style={{marginTop: 10, marginBottom: 10}}
                                    loading={isLoading}
                                >
                                    Log in
                                </Button>
                            </Form.Item>
                        </Form>
                    </Card>
                </div>
            </div>
            <Route
                path="/scanner/auth/code"
                render={() => (
                    <ScannerLoginModal
                        onFinish={(data) => {
                            formRef.current.setFieldsValue({
                                site_code: data,
                            });
                        }}
                    />
                )}
            />
        </>
    );
}
