import React from "react";
import {Redirect, Route, useLocation} from "react-router-dom";
import Cookies from "js-cookie";

function PublicRoute({component: Component, render, ...rest}) {
    const location = useLocation();
    if (String(location.pathname).slice(1, 8).toLowerCase() === "scanner") {
        return (
            <Route
                {...rest}
                render={({location}, props) =>
                    Cookies.get("site_id") !== undefined &&
                    Cookies.get("scanner_id") !== undefined ? (
                        <Redirect
                            to={{
                                pathname: "/scanner/entry",
                                state: {from: location},
                            }}
                        />
                    ) : Component ? (
                        <Component {...props} />
                    ) : (
                        render()
                    )
                }
            />
        );
    } else {
        return (
            <Route
                {...rest}
                render={({location}, props) =>
                    Cookies.get("site_id") !== undefined && Cookies.get("as") === "site" ? (
                        <Redirect
                            to={{
                                pathname: "/info",
                                state: {from: location},
                            }}
                        />
                    ) : Component ? (
                        <Component {...props} />
                    ) : (
                        render()
                    )
                }
            />
        );
    }
}

export default PublicRoute;
