import React, {useEffect, useState} from "react";
import Title from "antd/lib/typography/Title";
import ReactToPdf from "react-to-pdf";
import QRCode from "qrcode.react";
import Cookies from "js-cookie";
import {Button, message, Spin} from "antd";

export default function SiteInfoPage() {
    const ref = React.createRef();

    const [siteCode, setSiteCode] = useState("");
    const [siteName, setSiteName] = useState("");
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        fetch(`${process.env.REACT_APP_API_URL}/sites?id=${Cookies.get("site_id")}`, {
            headers: {
                Authorization: `Bearer ${Cookies.get("token")}`,
            },
        })
            .then((res) => res.json())
            .then((res) => {
                setSiteCode(res.code);
                setSiteName(res.name);
                setLoading(false);
            })
            .catch((e) => message.error(e.message));
    }, []);

    return (
        <div
            style={{
                textAlign: "center",
            }}
        >
            <Spin spinning={loading}>
                <div ref={ref}>
                    <Title level={3}>The site code for {siteName} is</Title>
                    <br/>
                    <br/>
                    <QRCode
                        value={siteCode}
                        size={175}
                        bgColor={"#ffffff"}
                        fgColor={"#000000"}
                        level={"L"}
                        includeMargin={false}
                        renderAs={"png"}
                    />
                    <br/>
                    <br/>
                    <Title level={4}>{siteCode}</Title>
                    <br/>
                    <br/>
                </div>
                <ReactToPdf
                    targetRef={ref}
                    filename="site code poster.pdf"
                    x={5}
                    y={5}
                >
                    {({toPdf}) => <Button onClick={toPdf}>Generate pdf</Button>}
                </ReactToPdf>
            </Spin>
        </div>
    );
}
